.player {
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-left: 100px;
  @media only screen and (max-width: 600px) {
    margin-left: 0px;
    min-height: 10vh;
    justify-content: center;
  }
}

.time-control {
  width: 50%;
  display: flex;
  align-items: center;
  input {
    width: 100%;
    -webkit-appearance: none;
    background: transparent;
    cursor: pointer;
  }

  p {
    padding: 1rem;
    color: white;
  }
}
@media only screen and (max-width: 600px) {
  .time-control {
    margin-top: -1.5rem;
  }
}
.play-control {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  margin: 0;
  width: 40%;
  svg {
    cursor: pointer;
  }
  color: white;
}

@media only screen and (max-width: 600px) {
  .play-control {
    padding: 1.5rem;
  }
}

.track {
  width: 100%;
  height: 1rem;
  position: relative;
  overflow: hidden;
  border-radius: 1rem;
}
.animate-track {
  background: rgb(204, 204, 204);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(0%);
  padding: 1rem;
  pointer-events: none;
}

@media only screen and (max-width: 600px) {
  .animate-track {
    padding: 0;
  }
}

input[type='range']:focus {
  outline: none;
}
input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 16px;
  width: 16px;
}

@media screen and (max-width: 768px) {
  .time-control {
    width: 90%;
  }
  .play-control {
    width: 80%;
  }
}
