* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  --primary-color: #ff7a30;
  --meta-color: #12181b;
}

body {
  font-family: 'Montserrat', sans-serif;
  background-color: rgb(27, 27, 27);
  @media only screen and (max-width: 600px) {
    margin-bottom: 5rem;
  }
}
.App {
  transition: all 0.5s ease;
}
.library-active {
  margin-left: 20%;
}

@media only screen and (max-width: 600px) {
  .library-active {
    margin-left: 20%;
    .song-container {
      display: none;
    }
    .player {
      display: none;
    }
  }
}

h1 {
  font-size: 1rem;
}
h2 {
  color: rgb(51, 51, 51);
}
h3,
h4 {
  color: rgb(100, 100, 100);
  font-weight: 400;
}

@import './song';
@import './player';
@import './library';
@import './nav';
