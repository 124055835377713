nav {
  margin-top: 15px;
  margin-left: 100px;
  min-height: 10vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
  @media only screen and (max-width: 600px) {
    margin-left: 0px;
  }
  button {
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0.5rem 1rem;
    border: 1.5px solid var(--primary-color);
    color: var(--primary-color);
    font-weight: 600;
    border-radius: 5px;
    outline: none;
    transition: all 0.3s ease;
    &:hover {
      border-color: rgb(27, 27, 27);
      background: var(--primary-color);
      color: var(--meta-color);
      outline: none;
    }
  }
  .library-active {
    border-color: rgb(27, 27, 27);
    background: var(--primary-color);
    color: var(--meta-color);
    outline: none;
  }
  .logo {
    width: 5rem;
    padding-top: 10px;
  }
}

@media screen and (max-width: 768px) {
  nav {
    button {
      z-index: 10;
    }
  }
}
