.song-container {
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 100px;
  @media only screen and (max-width: 600px) {
    margin-left: 0px;
  }
  img {
    width: 23%;
    border-radius: 50%;
    // transition: all 2s ease;
  }
  h2 {
    padding: 3rem 1rem 1rem 1rem;
    color: white;
  }
  h3 {
    font-size: 1rem;
  }
}

@media screen and (max-width: 600px) {
  .song-container {
    img {
      width: 45%;
    }
  }
}
@media screen and (max-width: 600px) {
  .song-container {
    h2 {
      padding: 2rem 1rem 1rem 1rem;
      color: white;
    }
  }
}

// @keyframes rotate {
//   from {
//     transform: rotate(0deg);
//   }
//   to {
//     transform: rotate(360deg);
//   }
// }

.rotateSong {
  animation: rotate 20s linear forwards infinite;
}
